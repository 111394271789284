<template>
  <svg
    width="120"
    height="120"
    viewBox="0 0 120 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="120" height="120" rx="60" fill="white" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M30.7851 78.3312C30.509 78.8095 30.6729 79.4211 31.1512 79.6972L40.307 84.9834L40.3072 84.983C41.579 82.7803 44.3956 82.0256 46.5984 83.2973C48.8011 84.5691 49.5558 87.3857 48.2841 89.5885L48.2839 89.5888L57.3381 94.8162C57.8164 95.0924 58.428 94.9285 58.7041 94.4502L74.6928 66.7569L74.6361 66.7721L73.4411 62.3123L73.4204 62.3481L70.9546 60.9245L71.7812 59.4928L74.211 60.8956L78.7276 59.6854L78.7428 59.7421L88.2151 43.3356C88.4912 42.8573 88.3274 42.2458 87.8491 41.9696L78.7872 36.7377C77.4534 38.7485 74.7673 39.3996 72.649 38.1766C70.5307 36.9536 69.7515 34.3018 70.826 32.1413L61.6622 26.8506C61.1839 26.5744 60.5723 26.7383 60.2961 27.2166L50.8239 43.623L52.0342 48.1399L52.055 48.1038L54.5208 49.5274L53.6942 50.9592L51.2284 49.5356L51.2907 49.4276L46.7739 50.6379L30.7851 78.3312ZM56.1599 52.3828L58.6257 53.8064L59.4523 52.3747L56.9866 50.9511L56.1599 52.3828ZM61.0915 55.23L63.5573 56.6536L64.3839 55.2219L61.9181 53.7983L61.0915 55.23ZM66.0231 58.0773L68.4888 59.5009L69.3154 58.0691L66.8497 56.6455L66.0231 58.0773Z"
      fill="#7B6CCC"
    />
  </svg>
</template>

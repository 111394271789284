<template>
  <ion-page>
    <ion-content>
      <SidebarNavigation active="EventsPage">
        <div class="background" v-if="!loadingComplimentaryTicket">
          <div class="header-container">
            <div class="flex">
              <button
                v-if="!isMobile()"
                style="margin-right: 16px"
                icon
                variant="white"
                @click="handleBack"
                data-tooltip="Vissza"
              >
                <arrow-back-icon />
              </button>
              <div variant="page-title">Tiszteletjegy: {{ complimentaryTicket.attributes.NID }}</div>
              <Chip :variant="complimentaryTicket.attributes.Status" type="complimentary-ticket"/>
            </div>
            <div class="flex">
              <button
                @click="() => (showEditStatusModal = true)"
                v-if="isEditVisible()"
                icon-left
                variant="white"
              >
                <edit-icon />
                Módosít
              </button>
              <button
                @click="() => (showAttendedModal = true)"
                :disabled="!verificationEnabled(complimentaryTicket)"
                variant="success"
                style="margin-right: 8px; margin-left: 8px"
                icon-left
                :data-tooltip="verificationEnabled(complimentaryTicket)|| complimentaryTicket.attributes.Status !== 'active'?null:'A verifikáció még nem aktív'"
              >
                <check-icon />
                Megjelent
              </button>
            </div>
          </div>
        </div>

        <div class="booking-details-container" v-if="!loadingComplimentaryTicket">
          <!-- COMPLIMENTARY TICKET DETAILS -->
          <div class="column-1">
            <div class="details-item">
              <h3 variant="table-head-button-label">Név</h3>
              <p variant="list-item">
                {{ complimentaryTicket?.attributes.Name || '- Nincs megadva -'}}
              </p>
            </div>
            <div class="details-item">
              <h3 variant="table-head-button-label">Résztvevők</h3>
              <p variant="list-item">
                {{ complimentaryTicket?.attributes.Participants }}
              </p>
            </div>
            <div class="details-item">
              <h3 variant="table-head-button-label">Érvényesítés dátuma</h3>
              <p variant="list-item">
                {{ complimentaryTicket?.attributes.VerifiedAt ? formatDateAndTime(complimentaryTicket?.attributes.VerifiedAt) : '-' }}
              </p>
            </div>
          </div>

          <!-- EVENT DETAILS -->
          <div class="column-2">
            <div class="details-item">
              <h3 variant="table-head-button-label">Esemény ID</h3>
              <p variant="list-item">
                {{ complimentaryTicket?.attributes.Event.data.attributes.NID }}
              </p>
            </div>
            <div class="details-item">
              <h3 variant="table-head-button-label">Esemény neve</h3>
              <p variant="list-item">
                {{ complimentaryTicket?.attributes.Event.data.attributes.Name }}
              </p>
            </div>
            <div class="details-item">
              <h3 variant="table-head-button-label">Esemény időpontja</h3>
              <p variant="list-item">
                {{
                  formatDateAndTime(
                    complimentaryTicket?.attributes.Event.data.attributes.StartAt
                  )
                }}-{{
                  formatDateAndTime(
                    complimentaryTicket?.attributes.Event.data.attributes.EndAt
                  )
                }}
              </p>
            </div>
            <div class="details-item">
              <h3 variant="table-head-button-label">Helyszín</h3>
              <p variant="list-item">
                {{ complimentaryTicket?.attributes.Event.data.attributes.LocationName }}
                <span style="color: var(--color-grey-03); margin-left: 8px">{{
                  complimentaryTicket?.attributes.Event.data.attributes.LocationAddress
                }}</span>
              </p>
            </div>
            <button
              @click="handleGoToEvent(complimentaryTicket)"
              icon-right
              variant="white"
              v-if="!isMobile()"
            >
              Ugrás az eseményhez <link-icon />
            </button>
          </div>
        </div>
      </SidebarNavigation>

      <!-- MODALS -->
      <div v-if="!loadingComplimentaryTicket">
        <AttendedModal
          type="complimentary-ticket"
          :visible="showAttendedModal"
          :handleStatusUpdate="handleUpdateModal"
          :handleClose="() => (showAttendedModal = false)"
          :booking="complimentaryTicket"
        />
        <NotAttendedModal
          :visible="showNotAttendedModal"
          :handleStatusUpdate="handleUpdateModal"
          :handleClose="() => (showNotAttendedModal = false)"
          :booking="complimentaryTicket"
        />
        <EditStatusModal
          :visible="showEditStatusModal"
          :handleStatusUpdate="handleUpdateModal"
          :handleClose="() => (showEditStatusModal = false)"
          :booking="complimentaryTicket"
        />
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent } from "@ionic/vue";
import SidebarNavigation from "@/components/SidebarNavigation";
import { useRoute, useRouter } from "vue-router";
import {
  ArrowBackIcon,
  CheckIcon,
  LinkIcon,
  EditIcon,
} from "../../assets/icons";
import useComplimentaryTicket from "../../hooks/useComplimentaryTicket";
import useFormatDate from "../../hooks/useFormatDate";
import useScreen from "../../hooks/useScreen";
import Chip from "../../components/ChipComponent.vue";
import dayjs from "dayjs";
import AttendedModal from "../bookings/AttendedModal.vue";
import NotAttendedModal from "../bookings/NotAttendedModal.vue";
import EditStatusModal from "../bookings/EditStatusModal.vue";
import { ref } from "@vue/reactivity";
export default {
  name: "ComplimentaryTicketDetailsPage",
  components: {
    ArrowBackIcon,
    IonPage,
    Chip,
    IonContent,
    SidebarNavigation,
    LinkIcon,
    CheckIcon,
    EditIcon,
    AttendedModal,
    NotAttendedModal,
    EditStatusModal,
  },
  setup() {
    const router = useRouter();
    const { params } = useRoute();
    const complimentaryTicketId = params.complimentaryTicketId;
    const { formatDateAndTime } = useFormatDate();

    const {isMobile} = useScreen();

    const {
      complimentaryTicket,
      loading: loadingComplimentaryTicket,
      update: updateComplimentaryTicket,
      getComplimentaryTicket,
    } = useComplimentaryTicket(complimentaryTicketId);

    const isEditVisible = () => {
      return false
    };

    const verificationEnabled = (complimentaryTicket) => {
      if(!complimentaryTicket) return;
      const eventStartAt = dayjs(complimentaryTicket.attributes.Event.data.attributes.StartAt);
      const eventEndAt = dayjs(complimentaryTicket.attributes.Event.data.attributes.EndAt);
      const now = dayjs();

      if(complimentaryTicket.attributes.Status !== "attended" && now < eventEndAt.add(24,"hours") && now > eventStartAt.add(-1, "hours")){
        return true;
      } else {
        return false;
      }
    }

    // MODAL
    const showAttendedModal = ref(false);
    const showNotAttendedModal = ref(false);
    const showEditStatusModal = ref(false);

    // HANDLERS
    const handleBack = () => router.back();
    const handleGoToEvent = (complimentaryTicket) =>
      router.push({
        name: "ActiveEventPage",
        params: { ...params, eventId: complimentaryTicket.attributes.Event.data.id },
      });
    const handleUpdateModal = async (complimentaryTicketId, status) => {
      const res = await updateComplimentaryTicket({ data: { Status: status } });
      if (res.ok) {
        showAttendedModal.value = false;
        showNotAttendedModal.value = false;
        showEditStatusModal.value = false;
        await getComplimentaryTicket();
      }
    };

    return {
      handleBack,
      handleGoToEvent,
      complimentaryTicket,
      isEditVisible,
      loadingComplimentaryTicket,
      updateComplimentaryTicket,
      formatDateAndTime,
      handleUpdateModal,
      showAttendedModal,
      showNotAttendedModal,
      showEditStatusModal,
      verificationEnabled,
      isMobile
    };
  },
};
</script>

<style scoped>
.header-container {
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.background {
  background-color: var(--color-white);
}
.booking-details-container {
  display: grid;
  margin: 8px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 8px;
  grid-row-gap: 0px;
}
.booking-details-container > div {
  background-color: var(--color-white);
  padding: 32px 24px;
}
.details-item {
  margin-bottom: 32px;
}
p,
h3 {
  margin: 0;
  margin-bottom: 8px;
}

@media only screen and (max-width: 768px) {
  .header-container {
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
  }

  .header-container div {
    margin-bottom: 12px;
  }

}
</style>
<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 0.5C3.41594 0.5 0.5 3.41594 0.5 7C0.5 10.5841 3.41594 13.5 7 13.5C10.5841 13.5 13.5 10.5841 13.5 7C13.5 3.41594 10.5841 0.5 7 0.5ZM9.5 7.5H7.5V9.5C7.5 9.63261 7.44732 9.75979 7.35355 9.85355C7.25979 9.94732 7.13261 10 7 10C6.86739 10 6.74021 9.94732 6.64645 9.85355C6.55268 9.75979 6.5 9.63261 6.5 9.5V7.5H4.5C4.36739 7.5 4.24021 7.44732 4.14645 7.35355C4.05268 7.25979 4 7.13261 4 7C4 6.86739 4.05268 6.74021 4.14645 6.64645C4.24021 6.55268 4.36739 6.5 4.5 6.5H6.5V4.5C6.5 4.36739 6.55268 4.24021 6.64645 4.14645C6.74021 4.05268 6.86739 4 7 4C7.13261 4 7.25979 4.05268 7.35355 4.14645C7.44732 4.24021 7.5 4.36739 7.5 4.5V6.5H9.5C9.63261 6.5 9.75979 6.55268 9.85355 6.64645C9.94732 6.74021 10 6.86739 10 7C10 7.13261 9.94732 7.25979 9.85355 7.35355C9.75979 7.44732 9.63261 7.5 9.5 7.5Z"
      fill="#FFF6F2"
    />
  </svg>
</template>

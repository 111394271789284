<template>
  <div class="action-container">
    <div class="left-actions">
      <!-- <button variant="white" @click="downloadTemplateCV(',')">
        Sablon letöltése (.csv)
      </button> -->
      <button variant="white" @click="downloadTemplateCV(';')">
        Sablon letöltése (.csv)
      </button>
      <!-- <a ref="downloadXlsxRef" href="/assets/tiszteletjegy-sablon.csv" target="_blank" variant="white" style="display: none;">
      </a> -->
      <button :disabled="uploadLoading" variant="rose" @click="() => uploadTemplateRef.click()">
        <span style="display: flex; gap: 8px">Tiszteletjegyek feltöltése <add-icon /></span>
      </button>
      <input
        :hidden="true"
        ref="uploadTemplateRef"
        type="file"
        @change="handleUploadTemplate"
        accept=".csv"
      />
    </div>
    <div class="right-actions">
      <!-- <button :disabled="sendLoading" variant="white" @click="openSendEmailModal">
        <span style="display: flex; gap: 8px">Kijelöltek elküldése <send-icon-2 /></span>
      </button> -->
    </div>
  </div>
  <table>
    <thead>
      <tr>
        <th class="no-style" colspan="4">
          <div class="info-container">
            <div style="min-width: 24px;"><InfoIcon size="24" color="#7B6CCC"/></div>
            <div variant="list-item">
              A Tiszteletjegyek létrehozásához 2019-es vagy ennél újabb Excel-t használj. A táblázatod minden celláját töltsd ki a megfelelő információval. <br>
              A sablon mentésekor a fájlformátumnál a <i><strong>CSV-fájl UTF-8 formátumban (.csv)</strong></i> opciót válaszd ki.
            </div>
          </div>
        </th>
        <th class="no-style" colspan="4">
          <div>
            <button :disabled="sendLoading" variant="white" @click="openSendEmailModal">
              <span style="display: flex; gap: 8px">Kijelöltek elküldése <send-icon-2 /></span>
            </button>
          </div>
        </th>
      </tr>
      <tr>
        <th>Sorozatszám</th>
        <th>Név</th>
        <th>E-mail cím</th>
        <th>Résztvevők</th>
        <th>Státusz</th>
        <th>
          <div class="checkbox-override">
            <CheckboxComponent @change="selectAll" v-model="allSelected"/>
          </div>
        </th>
        <th>Érvényesítés dátuma</th>
        <th></th>
      </tr>
    </thead>
    <tbody v-if="complimentaryTickets?.length">
      <tr v-for="complimentaryTicket in complimentaryTickets"  style="cursor: pointer" @click="handleComplimentaryTicketPage(complimentaryTicket.id)" :key="complimentaryTicket.attributes.NID">
        <td>{{ complimentaryTicket.attributes.NID }}</td>
        <td>{{ complimentaryTicket.attributes.Name  || '- Nincs megadva -'}}</td>
        <td>{{ complimentaryTicket.attributes.Email || '- Nincs megadva -'}}</td>
        <td>{{ complimentaryTicket.attributes.Participants }}</td>
        <td>
          <Chip :variant="complimentaryTicket.attributes.Status" type="complimentary-ticket"/>
        </td>
        <!-- IF EVENT IS GIVEN DONT SHOW THE NAME -->
        <td @click.stop="()=>{}" style="display: flex;">
          <div v-if="complimentaryTicket.attributes.Status !== 'attended'" class="checkbox-override">
            <CheckboxComponent v-model="selectedTickets[complimentaryTicket.id]" />
          </div>
        </td>
        <td>
          {{ complimentaryTicket.attributes.VerifiedAt ? formatDateAndTime(complimentaryTicket.attributes.VerifiedAt) : ''}}
        </td>
        <!-- <td><Chip :variant="booking.attributes.Status" /></td> -->
        <!-- ACTION BUTTONS -->
        <td>
          <div style="display: flex">
            <button
              :disabled="!verificationEnabled(complimentaryTicket, event)"
              :data-tooltip="verificationEnabled(complimentaryTicket, event) ? 'Megjelent':'A verifikáció még nem aktív'"
              v-if="complimentaryTicket.attributes.Status !== 'attended'"
              variant="success"
              icon
              @click.stop="() => (toBeAttendedBooking = complimentaryTicket)"
            >
              <check-icon />
            </button>
            <button
              @click.stop="() => (toBeEditedBooking = complimentaryTicket)"
              icon
              variant="white"
              v-if="isEditVisible(complimentaryTicket, event)"
              data-tooltip="Szerkesztés"
            >
              <edit-icon />
            </button>
            <button
              data-tooltip="Megtekintés"
              @click.stop="handleComplimentaryTicketPage(complimentaryTicket.id)"
              variant="white"
              icon
            >
              <chevron-right-icon />
            </button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <page-selector
    v-if="complimentaryTickets?.length"
    :currentPage="page"
    :max="pageCount"
    :onClick="handleSelectPage"
  />
  <AttendedModal
    type="complimentary-ticket"
    :visible="!!toBeAttendedBooking"
    :booking="toBeAttendedBooking"
    :handleClose="() => (toBeAttendedBooking = null)"
    :handleStatusUpdate="handleComplimentaryTicketUpdate"
  />
  <NotAttendedModal
    :visible="!!toBeNotAttendedBooking"
    :booking="toBeNotAttendedBooking"
    :handleClose="() => (toBeNotAttendedBooking = null)"
    :handleStatusUpdate="handleComplimentaryTicketUpdate"
  />
  <EditStatusModal
    :visible="!!toBeEditedBooking"
    :booking="toBeEditedBooking"
    :handleClose="() => (toBeEditedBooking = null)"
    :handleStatusUpdate="handleComplimentaryTicketUpdate"
  />
  <SendWithMessageModal
    :visible="sendWithMessageModalVisible"
    :handleClose="() => (sendWithMessageModalVisible = null)"
    :handleSend="sendComplimentaryTickets" />
  <div v-if="!complimentaryTickets?.length || complimentaryTickets?.length == 0">
    <div class="empty-state">
      <div>
        <ticket-icon />
      </div>
      <p variant="page-title">
        Ehhez az eseményhez <br> még nem tartozik tiszteletjegy.
      </p>
      <div class="info">
        <span variant="body-bold-14">Tiszteletjegyek létrehozása:</span>
        <ol>
          <li variant="list-item">
            <span>Töltsd le a sablont a tiszteletjegyek létrehozásához a <i><strong>"Sablon letöltése (.csv)"</strong></i> gombra kattintva!</span>
          </li>
          <li variant="list-item">
            Töltsd ki a táblázatot a vonatkozó információkkal!
          </li>
          <li variant="list-item">
            Mentsd el a kitöltött sablon táblázatot <i><strong>CSV-fájl UTF-8 formátumban (.csv) </strong></i> !
          </li>
          <li variant="list-item">
            A kitöltött sablont töltsd fel ide a <i><strong>"Tiszteletjegyek feltöltése" </strong></i> gombra kattintva!
          </li>
          <li variant="list-item">
            A feltöltött tiszteletjegyek a kijelölésüket követően küldhetőek el a címzetteknek a <i><strong>"Kijelöltek elküldése" </strong></i> gombra kattintva.
          </li>
        </ol>
      </div>
    </div>

  </div>
</template>

<script>
import {
  CheckIcon,
  ChevronRightIcon,
  EditIcon,
  TicketIcon,
  SendIcon2,
  AddIcon,
  InfoIcon
} from "../../assets/icons";
import Chip from "../../components/ChipComponent.vue";
import dayjs from "dayjs";
import { useRouter } from "vue-router";
import useFormatDate from "../../hooks/useFormatDate";
import AttendedModal from "../bookings/AttendedModal.vue";
import NotAttendedModal from "../bookings/NotAttendedModal.vue";
import EditStatusModal from "../bookings/EditStatusModal.vue";
import { ref } from "@vue/reactivity";
import useComplimentaryTickets from "../../hooks/useComplimentaryTickets"
import PageSelector from '../../components/PageSelector.vue';
import complimentaryTicketApi from '../../api/complimentary-ticket';
import CheckboxComponent from '../../components/CheckboxComponent.vue';
import Papa from 'papaparse';
import { useToast } from 'vue-toastification';
import SendWithMessageModal from './SendWithMessageModal.vue';

export default {
  props: ["onUpdateFinished", "event"],
  components: {
    EditIcon,
    CheckIcon,
    ChevronRightIcon,
    InfoIcon,
    Chip,
    AttendedModal,
    NotAttendedModal,
    EditStatusModal,
    PageSelector,
    TicketIcon,
    SendIcon2,
    CheckboxComponent,
    AddIcon,
    SendWithMessageModal,
  },
  setup(props) {
    const { params } = useRouter();
    const router = useRouter();
    const { formatDateAndTime } = useFormatDate();
    const toBeAttendedBooking = ref(null);
    const toBeNotAttendedBooking = ref(null);
    const toBeEditedBooking = ref(null);
    const loading = ref(false);
    const uploadTemplateRef = ref(null);
    const { error, success } = useToast()
    const selectedTickets = ref({});
    const allSelected = ref(false);
    const sendWithMessageModalVisible = ref(null);
    const uploadLoading = ref(false);
    const sendLoading = ref(false);
    const downloadXlsxRef = ref(null)

    const { 
      complimentaryTickets, 
      loading: complimentaryTicketsLoading, 
      getComplimentaryTickets, 
      page, 
      pageCount, 
      total 
    } = useComplimentaryTickets(props.event.id, 20)

    const isEditVisible = () => {
      return false
    };

    const verificationEnabled = (complimentaryTicket, event) => {
      if(!complimentaryTicket) return
      const eventStartAt = dayjs(event
        ? event.attributes.StartAt : complimentaryTicket.attributes.Event.data.attributes.StartAt);
      const eventEndAt = dayjs(event
        ? event.attributes.EndAt : complimentaryTicket.attributes.Event.data.attributes.EndAt);
      const now = dayjs();

      if(complimentaryTicket.attributes.Status !== "attended" && now < eventEndAt.add(24,"hours") && now > eventStartAt.add(-1, "hours")){
        return true;
      } else {
        return false;
      }
    }

    const handleUploadTemplate = (e) => {
      const file = e.target.files[0];
      if (!file) {
        return;
      }
      uploadLoading.value = true;
      const reader = new FileReader();
      reader.onload = async (event) => {
        const csvContent = event.target.result;
        // You can parse the CSV content here and display it as needed
        const { data, errors} = Papa.parse(csvContent);
        if(errors.length > 0){
          error('Hiba a fájl feldolgozása során')
          uploadLoading.value = false;
          e.target.value = null;
          return
        }
        if(data[0][0] !== 'Név' || data[0][1] !== 'E-mail cím' || data[0][2] !== 'Résztvevők'){
          error('Hibás fájl formátum, a sablonban használt oszlopneveket használd')
          uploadLoading.value = false;
          e.target.value = null;
          return
        }

        if(data.length < 2){
          error('A fájl üres (legfeljebb a fejléc van benne)')
          uploadLoading.value = false;
          e.target.value = null;
          return
        }

        const newComplimentaryTickets = data.slice(1).filter(row=>row.length === 3).map(row => {
          try {
            const participants = parseInt(row[2])
            return {
              Name: row[0],
              Email: row[1],
              Participants: participants,
              Event: props.event.id
            }
          } catch (error) {
            return {
              Name: row[0],
              Email: row[1],
              Participants: -1,
              Event: props.event.id
            }
          }
        })

        let wrongEmails = [];
        let wrongParticipants = [];
        console.log(newComplimentaryTickets)
        newComplimentaryTickets.forEach(ticket => {
          if(!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(ticket.Email)){
            wrongEmails.push(ticket.Email)
          }
        })

        newComplimentaryTickets.forEach(ticket => {
          if(ticket.Participants <= 0 || isNaN(ticket.Participants)){
            wrongParticipants.push(ticket.Name)
          }
        })

        if(wrongEmails.length > 0 || wrongParticipants.length > 0){
          let errorMessage = "Nem sikerült a feltöltés, az alábbi hibákat találtuk.\n"
          if(wrongParticipants.length > 0){
            errorMessage += "A következő nevek mellett hibás a résztvevők száma: " + wrongParticipants.join(', ') + ".\n"
          }
          if(wrongEmails.length > 0){
            errorMessage += "A következő email címek hibásak: " + wrongEmails.join(', ') + ".\n"
          }
          uploadLoading.value = false;
          e.target.value = null;
          error(errorMessage)
          return
        }


        const res = await complimentaryTicketApi.bulkUpload({
          data: newComplimentaryTickets
        })
        success("Sikeres fájlfeltöltés")

        if(res.ok){
          getComplimentaryTickets(page.value)
        }
        uploadLoading.value = false;

        e.target.value = null;

      };
      reader.readAsText(file);
    }

    const sendComplimentaryTickets = async (message) => {
      sendLoading.value = true
      sendWithMessageModalVisible.value = false
      const selectedComplimentaryTicketIds = Object.keys(selectedTickets.value).filter((key) => selectedTickets.value[key]).map((key) => parseInt(key));
      if(selectedComplimentaryTicketIds.length === 0){
        error('Nincs kijelölt tiszteletjegy')
        return
      }
      const res = await complimentaryTicketApi.bulkSend({
        data: {
          ids: selectedComplimentaryTicketIds,
          Message: message
        }
      })
      if(res.ok){
        getComplimentaryTickets(page.value)
        success('Tiszteletjegyek elküldve')
        allSelected.value = false
        selectedTickets.value = {}
      }
      sendLoading.value = false
    }

    const selectAll = (e) => {
      complimentaryTickets.value.filter(complimentaryTicket => complimentaryTicket.attributes.Status !== 'attended').forEach((complimentaryTicket) => {
        selectedTickets.value[complimentaryTicket.id] = e.target.checked;
      });
    };

    const handleSelectPage = (page) => {
      getComplimentaryTickets(page)
      selectedTickets.value = {}
      allSelected.value = false
    }

    const handleComplimentaryTicketUpdate = async (complimentaryTicketId, status) => {
      const res = await complimentaryTicketApi.update(
        { data: { Status: status } },
        complimentaryTicketId
      );
      if (res.ok) {
        toBeAttendedBooking.value = null;
        toBeNotAttendedBooking.value = null;
        toBeEditedBooking.value = null;
        getComplimentaryTickets(page.value)
      }
    };

    const handleComplimentaryTicketPage = (complimentaryTicketId) =>
      router.push({
        name: "ComplimentaryTicketDetailsPage",
        params: { ...params, complimentaryTicketId },
      });
  
    const openSendEmailModal = () => {
      if(Object.keys(selectedTickets.value).filter((key) => selectedTickets.value[key]).map((key) => parseInt(key)).length === 0){
        error('Nincs kijelölt tiszteletjegy')
        return
      }
      sendWithMessageModalVisible.value = true
    }

    return {
      isEditVisible,
      handleComplimentaryTicketPage,
      formatDateAndTime,
      toBeAttendedBooking,
      toBeNotAttendedBooking,
      toBeEditedBooking,
      sendWithMessageModalVisible,
      handleComplimentaryTicketUpdate,
      loading,
      verificationEnabled,

      complimentaryTickets,
      complimentaryTicketsLoading,
      uploadTemplateRef,
      downloadXlsxRef,
      handleUploadTemplate,
      selectedTickets,
      sendComplimentaryTickets,
      selectAll,
      allSelected,
      uploadLoading,
      sendLoading,
      openSendEmailModal,

      getComplimentaryTickets,
      page,
      pageCount,
      total,
      handleSelectPage
    };
  },
  created(){
    this.getComplimentaryTickets(1, {})
  },
  methods: {
    downloadTemplateCV(separator = ',') {
      // const separator = navigator.userAgent.includes('Windows') ? ';' : ',';
      const csvContent = "data:text/csv;charset=utf-8," 
        + "\uFEFF"+"Név" + separator + "E-mail cím" + separator + "Résztvevők\n"
        + "Név 1" + separator + "nev1@pelda.hu" + separator + "3\n"
        + "Név 2" + separator + "nev2@pelda.hu" + separator + "2\n";

      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "tiszteletjegyfeltoltes_sablon.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
};
</script>

<style scoped>
  th.no-style{
    border: none;
    background-color: var(--color-red-06);
    vertical-align: bottom;
  }

  .action-container {
    display: flex;
    margin: 8px 32px;
    justify-content: space-between;
  }

  .info-container {
    display: flex;
    gap: 8px;
    margin: 0px 8px;
    align-items: flex-start;
    max-width: 699px;
  }

  .left-actions {
    display: flex;
    gap: 24px;
  }

  .right-actions {
    display: flex;
    justify-content: flex-end;
  }

  .empty-state {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-bottom: 24px;
  }
  .empty-state p {
    text-align: center;
    max-width: 600px;
  }

  .empty-state .info {
    background: var(--color-red-07);
    border-radius: 4px;
    padding: 48px;
  }

  .empty-state .info ol {
    padding-left: 0;
    margin-left: 16px;
  }

  .empty-state .info ol li {
    margin-bottom: 4px;
  }

  .checkbox-override .checkbox {
    margin-top: 8px;
    margin-bottom: 8px !important;
  }

  .checkbox-override {
    display: flex;
    align-items: center;
    height: 100%;
  }
</style>
<template>
  <Modal v-if="visible" @close="handleClose">
    <div class="body-container">
      <svg
        width="64"
        height="65"
        viewBox="0 0 64 65"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect y="0.5" width="64" height="64" rx="32" fill="#7B6CCC" />
        <path
          d="M12.5 45.9306C12.5 39.9241 13.8538 35.125 16.5228 31.6562C19.7459 27.4684 24.8366 25.2091 31.6691 24.9297V15.25L51.5 32.125L31.6691 49V39.3766C27.5206 39.4881 24.3884 40.0872 21.8937 41.2366C19.1984 42.4788 17.2438 44.335 15.1363 46.7397L12.5 49.75V45.9306Z"
          fill="white"
        />
      </svg>

      <p variant="page-title" style="margin-bottom: 8px">
        Tiszteletjegyek elküldése
      </p>

      <div style="margin-bottom: 12px" variant="list-item">
        Írd meg, hogy milyen üzenettel kapják meg a címzettek a tiszteletjegyüket!
      </div>

      <textarea
        rows="10"
        placeholder="Üzenet..."
        v-model="message"
        style="width: 100%; height: 120px;" />
      
      <div class="flex" style="margin-top: 24px">
        <button
          style="min-width: 120px; margin-right: 16px"
          @click="handleClose"
          variant="white"
        >
          Mégsem
        </button>
        <button
          style="min-width: 120px"
          @click="handleSend(message)"
        >
          Tiszteletjegyek elküldése
        </button>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from "../../components/ModalComponent.vue";
export default {
  components: {
    Modal,
  },
  props: ["visible", "handleClose", "handleSend"],
  data() {
    return {
      message: "",
    };
  },
};

</script>

<style>
.body-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px 60px;
}
</style>
